export function show(container, image) {
  $('#captcha-container').remove()
  const captchaContainer = $('<div id="captcha-container"/>')
    .css({ marginBottom: 20 })
    .prependTo(container)

  $('<p/>')
    .text('Para prosseguir, informe o resultado da soma')
    .appendTo(captchaContainer)

  $('<img/>')
    .attr('src', 'data:image/png;base64,' + image)
    .appendTo(captchaContainer)

  $('<input name="captcha[response]" type="number">')
    .addClass('form-control')
    .css({ width: 90, display: 'inline', marginLeft: 20 })
    .appendTo(captchaContainer)
}
