import { execute } from './recaptcha'

export function saveEmail(value, sitekey) {
  const options = {
    action: 'finish_order',
  }

  return execute(sitekey, options, token => $.post('/recaptcha/' + token)).then(
    () => $.post('/checkout/email', { email: value }, $.noop, 'json'),
  )
}

export function gotoCheckout() {
  return new Promise(function(resolve) {
    if (window.google_tag_manager && window.dataLayer && dataLayer.push) {
      dataLayer.push({
        event: 'finish-order',
        eventCallback: function() {
          location.href = '/checkout'
          resolve()
        },
        eventTimeout: 2000,
      })
    } else {
      location.href = '/checkout'
      resolve()
    }
  })
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  return re.test(email)
}
