const addCartHandler = (actionData) => {
  const { data } = actionData
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          ...data,
          quantity: data.quantity || 1,
        },
      ],
    },
  })
}

const removeCartHandler = (actionData) => {
  const { data } = actionData
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          ...data,
          quantity: data.quantity || 1,
        },
      ],
    },
  })
}

const searchHandler = (actionData) => {
  window.window.dataLayer.push({
    event: 'search',
    search_term: actionData.data.q,
  })
}
const loginHandler = (actionData) => {
  window.window.dataLayer.push({
    event: 'login',
    method: actionData.data.method,
  })
}
const signUpHandler = (actionData) => {
  window.window.dataLayer.push({
    event: 'sign_up',
    method: actionData.data.method,
  })
}

const withValidations = (registerHandler) => (actionName, handler) => {
  return registerHandler(actionName, (event) => {
    if (window.dataLayerVersion !== 'GA4' || !window.dataLayer) return () => {}
    handler(event)
  })
}

export const ACTIONS = {
  ADD_CART: 'add-cart',
  DELETE_ITEM_CART: 'delete-item-cart',
  SEARCH: 'search',
  LOGIN: 'login',
  REGISTER: 'register',
}

export const registerGA4DataLayerHandlers = (registerHandler) => {
  const registerWithValidations = withValidations(registerHandler)

  registerWithValidations(ACTIONS.ADD_CART, addCartHandler)
  registerWithValidations(ACTIONS.DELETE_ITEM_CART, removeCartHandler)
  registerWithValidations(ACTIONS.SEARCH, searchHandler)
  registerWithValidations(ACTIONS.LOGIN, loginHandler)
  registerWithValidations(ACTIONS.REGISTER, signUpHandler)
}
