import { registerGA4DataLayerHandlers } from './dataActions.dataLayerGA4'

const handlers = {}

export const registerHandler = (action, handler) => {

  if (!handlers[action]) {
    handlers[action] = []
  }
  handlers[action].push(handler)
}

export const getActionDataFromElement = (el) => {
  if (!el) return
  const json = JSON.parse(el.getAttribute('data-event-json'))
  const quantity = el.getAttribute('data-event-quantity') || undefined
  const item_variant =
    el.getAttribute('data-event-variant-selected') || json.item_variant
  const data = {
    ...json,
    quantity,
    item_variant,
  }

  return {
    element: el,
    action: el.getAttribute('data-action'),
    data,
  }
}

const getActionData = (event) => {
  if (!event.target) return
  if (!!event.target.getAttribute('data-action')) {
    return getActionDataFromElement(event.target)
  } else if (!!event.target.parentElement.getAttribute('data-action')) {
    return getActionDataFromElement(event.target.parentElement)
  }
}

const onClickHandler = (event) => {
  emitEvent(getActionData(event))
}

const startListeners = () => {
  window.addEventListener('click', onClickHandler)
  dispatchSearchEvent()
  dispatchAuthEvents()
}

const dispatchAuthEvents = () => {
  const action = {
    '#login': 'login',
    '#register': 'register',
  }[window.location.hash]

  if (action) {
    emitEvent({
      element: window,
      action,
      data: {
        method: 'credentials',
      },
    })
    window.location.hash = ''
  }
}

const dispatchSearchEvent = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.get('q')) {
    emitEvent({
      element: window,
      action: 'search',
      data: Object.fromEntries(params.entries()),
    })
  }
}

const processUncollectedInitialEvents = () => {
  if (window.vdaFn) {
    window.removeEventListener('click', window.vdaFn)
    window.vda.forEach((event) => emitEvent(getActionData(event)))
    window.vadFn = undefined
    window.vda = undefined
  }
}

const emitEvent = (dataAction) => {
  if (!dataAction) return
  if (handlers[dataAction.action]) {
    handlers[dataAction.action].forEach((handler) => handler(dataAction))
  }
}

export const init = () => {
  registerGA4DataLayerHandlers(registerHandler)
  
  startListeners()
  processUncollectedInitialEvents()
}

export const unregisterAllHandlers = () =>{
  window.removeEventListener('click', onClickHandler)
  Object.keys(handlers).forEach((action) => {
    handlers[action] = []
  })
}

export const getHandlers = () => handlers
