export function execute(sitekey, options, success) {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.grecaptcha && grecaptcha.execute && sitekey) {
        await grecaptcha.execute(sitekey, options).then(success || $.noop)
      }
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}
