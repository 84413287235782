window.Vnda = $.extend(window.Vnda || {}, require('./vndaApi'))
window.vnda = window.vnda || window.Vnda || {}

window.Vnda.dataActions.init()

var js_file = $('#vndajs')
if (js_file.data('variant')) {
  vnda.variants.feed(js_file.data('variant'))
}

// Banners Google Analytics
$('[data-content-type="banner"]').each(function() {
  if (window.ga) {
    var elm = $(this)

    // Add impression event
    ga('ec:addPromo', {
      id: elm.attr('alt'),
      name: elm.attr('alt'),
      position: elm.attr('data-banner-position'),
    })

    //Check if its a link and send click event
    var parent = $(this).parent()
    if (parent.is('a')) {
      parent.on('click', function() {
        ga('ec:setAction', 'promo_click')
        ga('send', 'event', 'Internal Promotions', 'click', $(this).attr('alt'))
      })
    }
  }
})


// Load product_also_viewed.liquid
if ($('#product-also-viewed').length > 0) {
  $.ajax({
    type: "GET",
    url: '/produto/visualizado',
    success: function(response){
      $('#product-also-viewed').html(response);
    }
  });
}
