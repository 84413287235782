export const variant_list = []

export function feed(html_variants) {
  const variants = JSON.parse(JSON.stringify(html_variants))

  for (let index = 0; index < variants.length; ++index) {
    const variant = variants[index]
    if (variant.properties) {
      if (typeof variant.properties['property1'] === 'string') {
        variant.properties['property1'] = JSON.parse(
          variant.properties['property1'],
        )
      }

      if (typeof variant.properties['property2'] === 'string') {
        variant.properties['property2'] = JSON.parse(
          variant.properties['property2'],
        )
      }

      if (typeof variant.properties['property3'] === 'string') {
        variant.properties['property3'] = JSON.parse(
          variant.properties['property3'],
        )
      }

      variant_list.push(variant)
    }
  }
}

export function find_by_attribute(properties) {
  return all().filter(function(variant) {
    var match = true
    for (var property_key in properties) {
      const property = variant.properties[property_key]
      if (properties[property_key] !== property.value) {
        match = false
      }
    }
    return match
  })
}

export function find(properties) {
  return variant_list.filter(function(variant) {
    var match = true
    for (var property_key in properties) {
      var property1 = variant.properties['property1']
      var property2 = variant.properties['property2']
      var property3 = variant.properties['property3']

      if (property1 && property_key === property1.name) {
        var desired_property = property1.value
      } else if (property2 && property_key === property2.name) {
        var desired_property = property2.value
      } else if (property3 && property_key === property3.name) {
        var desired_property = property3.value
      }

      if (desired_property !== properties[property_key]) {
        match = false
      }
    }
    return match
  })
}

export function all() {
  return variant_list
}
