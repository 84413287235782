export function hide(container) {
  if (!container) {
    container = document.body
  }

  $(container)
    .find('.page-loader')
    .remove()
}

export function show(container) {
  if (!container) {
    container = document.body
  }

  hide(container)
  const html =
    '<div class="page-loader"><div class="spinner"><div class="double-bounce1"></div><div class="double-bounce2"></div></div></div>'
  $(html).appendTo(container)
}
